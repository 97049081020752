import { Box, Button, LinearProgress, Typography } from "@mui/material";
import axios from "../axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "fullName",
    headerName: "Full name",
    width: 300,
  },
  {
    field: "class",
    headerName: "Class",
    width: 130,
    valueGetter: (params) => {
      if (!params.row.class) return "No class";
      return params.row.class.name;
    },
  },
  {
    field: "gender",
    headerName: "Group",
    width: 130,
    valueGetter: (params) => (params.row.gender === "MALE" ? "BOYS" : "GIRLS"),
  },
  {
    field: "dateOfBirth",
    headerName: "Date of Birth",
    width: 130,
  },
  {
    field: "admissionDate",
    headerName: "Date of Admission",
    width: 130,
  },
  {
    field: "parentName",
    headerName: "Parent Name",
    width: 300,
    valueGetter: (params) => params.row.parent.fullName,
  },
  {
    field: "parentPhone",
    headerName: "Parent Phone",
    width: 200,
    valueGetter: (params) => params.row.parent.phone,
  },
  {
    field: "isActive",
    headerName: "Active",
    sortable: false,
    width: 130,
    valueGetter: (params) => (params.row.isActive ? "YES" : "NO"),
  },
];

const Students = () => {
  const { data, isLoading } = useQuery("students", () =>
    axios.get("/students").then((res) => res.data)
  );

  const navigate = useNavigate();

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography variant="h5" component="h2">
          Students
        </Typography>
        <Button variant="contained" onClick={() => navigate("/students/new")}>
          New Student
        </Button>
      </Box>
      <Box sx={{ height: 650, width: "100%" }}>
        <DataGridPro
          onRowDoubleClick={(params) => navigate(`/students/${params.row.id}`)}
          rows={data || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          loading={isLoading}
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
        />
      </Box>
    </div>
  );
};
export default Students;
